<template>
	<div>
		<vue-autosuggest
			ref="autosuggest"
			v-model="query"
			:class="validateError != '' ? 'box-border-color' : ''"
			:suggestions="suggestions"
			:inputProps="inputProps"
			:sectionConfigs="sectionConfigs"
			:getSuggestionValue="getSuggestionValue"
			@input="handleInput">
			<template v-slot="{ suggestion }">
				<div>{{ suggestion.item.student_number+' '+suggestion.item.name + ' ' + suggestion.item.surname }}</div>
			</template>
		</vue-autosuggest>
		<span class="invalid-feedback-custom" v-show="validateError" v-html="validateError"/>
	</div>
</template>
<script>
	import StudentService from "@/services/StudentService";
	
	export default {
		props: {
			value: {
				default: null
			},
			validateError: {
				type: String,
				default: ''
			}
		},

		data() {
			return {
				user: null,
				searchText: "",
				debounceMilliseconds: 200,
				inputProps: {
					id: "autosuggest__input",
					placeholder: `${this.$t('search')} ...`,
					class: "form-control"
				},
				query: "",
				selected: "",
				data: [],
				sectionConfigs: {
					limit: 10,
					default: {
						onSelected: (item) => {
							if (item) {
								this.user = item.item;
								this.$emit('user-input', this.user)
							}
						}
					}
				},
				items: [],
				fields: [
					{key: 'person.name', label: this.$options.filters.toUpperCase(this.$t('name')), sortable: true},
					{key: 'person.surname', label: this.$options.filters.toUpperCase(this.$t('surname')), sortable: true},
					{
						key: this.getLocaleField('type_name'),
						label: this.$options.filters.toUpperCase(this.$t('member_type')),
						sortable: true
					},
					{key: 'status', label: this.$t('status'), sortable: true, formatter: this.getStatusText},
					{key: 'actions', label: '', sortable: false, class: 'w-40'},
				],
			}
		},
        computed: {
            suggestions() {
                return [
                    {
                        data: this.data.filter(option => {
                            return option.name + ' ' + option.surname
                        })
                    }
                ];
            }
        },
        watch: {
            value: function (newValue) {
                this.value = newValue
                this.query = newValue
                this.handleInput()
            },
        },
		methods: {
			getSuggestionValue(suggestion) {
				let {name, item} = suggestion;
				return item.student_number+' '+item.name + ' ' + item.surname;
			},
			
			findUser(query) {
				const selectedUser = this.data.find((s) => `${s.name} ${s.surname}` === query)
				return selectedUser
			},
			
			handleInput() {
                const query = this.query;
				this.user = this.findUser(query)
				this.$emit('user-input', this.user)

				if (query.length < 3) {
					return;
				}
				
				clearTimeout(this.timeout);
				this.timeout = setTimeout(() => {
					const config = {
						params: {
							key: query,
							class: '0'
						}
					};
					
					StudentService.search(config)
					              .then((response) => {
						              this.data = response.data.data;
						              this.user = this.findUser(query)
						              this.$emit('user-input', this.user)
					              });
					
				}, this.debounceMilliseconds);
			}
		}
	}
</script>
