<template>
    <div>
        <ValidationObserver ref="form">
            <b-row>

                <b-col cols="12">
                    <ValidationProvider name="academic_year" rules="required" v-slot="{ errors }">
                        <b-form-group :label="$t('academic_year')">
                            <academic-years-selectbox :validate-error="errors[0]" v-model="form.academic_year"/>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col cols="12">
                    <ValidationProvider name="semester_id" rules="required" v-slot="{ errors }">
                        <b-form-group :label="$t('module')">
                            <module-selectbox valueType="id"
                                              :academic_year="form.academic_year"
                                              :validate-error="errors[0]"
                                              v-model="form2.module_id"/>
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col cols="12">
                    <b-form-group :label="$t('student_numbers')">
                        <lined-textarea v-model="form.student_number"
                                        :onlyNumberAndEnter="true"
                                        :styles="{ height: '15em', resize: 'both' }"
                        >
                        </lined-textarea>
                    </b-form-group>
                </b-col>

                <div class="col-12 mt-3 d-flex">
                    <b-button @click="sendForm()"
                              type="button"
                              variant="primary"
                              class="btn-lg mr-2">
                        {{ $t('update').toUpper() }}
                    </b-button>
                </div>
            </b-row>
        </ValidationObserver>
        <CommonModal ref="massAbsenceResult" size="md" :onHideOnlyX="true">
            <template v-slot:CommonModalTitle>
                {{ $t('results') }}
            </template>
            <template v-slot:CommonModalContent>
                <b-table striped hover
                         :items="items"
                         :fields="fields"
                >
                    <template #cell(status)="row">
                        <span class="badge badge-danger" v-if="row.item.status==='NOT_UPDATED'">Not Updated</span>
                        <span class="badge badge-success" v-else>Updated</span>
                    </template>
                </b-table>
            </template>
        </CommonModal>
    </div>
</template>
<script>
import LinedTextarea from "@/components/elements/LinedTextarea.vue";
import ModuleSelectbox from "@/components/interactive-fields/ModuleSelectbox.vue";
import PrepStudentService from "@/services/PrepStudentService";

export default {
    components: {ModuleSelectbox, LinedTextarea},
    data() {
        return {
            items: [],
            fields: [
                {
                    label: this.$t('student_number'),
                    key: 'student_number',
                },
                {
                    label: this.$t('status'),
                    key: 'status',
                }
            ],
            updatedStudentData: null,
            form: {
                student_number: ''
            },
            form2: {
                student_numbers: null
            }
        }
    },
    methods: {
        async sendForm() {
            let isValid = await this.$refs.form.validate()
            if (isValid) {
                delete this.form.academic_year;
                if (this.form.student_number != null && this.form.student_number != '') {
                    this.form2.student_numbers = this.form.student_number.split(/\n/)
                } else {
                    delete this.form.student_number
                }
                PrepStudentService.massAbsenceUpdate(this.form2)
                    .then(response => {
                        this.$toast.success(this.$t('absence_success_updated'))
                        this.showUpdate(response.data.data)
                    }).catch(err => {
                    this.showErrors(err)
                })
            }

        },
        showUpdate(data) {
            this.items = data
            this.$refs.massAbsenceResult.$refs.commonModal.show();
        }
    }
}
</script>
