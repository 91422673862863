<template>
    <div>
        <app-layout>
	        <template v-slot:header>
	            <Header :title="$t('prep_students')"
	                    :isNewButton="checkPermission('prepstudent_store')"
	                    :isColumns="true"
                        :isImportButton="checkPermission('prepstudent_import')"
                        :actions="actions"
                        @import-button-click="importData"
	                    @new-button-click="showModal('create')"
	                    @filter-div-status="datatable.filterStatus=$event"
                        :other-button="{show:true,icon:'ri-pass-valid-line',title:$t('mass_absence_update'),emit:'massAbsenceUpdate'}"
                        @massAbsenceUpdate="showMaasAbsenceUpdate()"
	            >
	                <template v-slot:columns>
	                    <div class="mb-1" v-for="(column,key) in datatable.columns">
	                        <b-form-checkbox
	                            :id="'checkbox-'+key"
	                            :name="'checkbox-'+key"
	                            :value="false"
	                            :unchecked-value="true"
	                            v-model="column.hidden"
	                            v-if="column.field!='buttons'"
	                        >
	                            {{ column.label }}
	                        </b-form-checkbox>
	                    </div>
	                </template>
	            </Header>
	        </template>
	        <template v-slot:header-mobile>
		        <HeaderMobile :title="$t('prep_students')"
		                      :isNewButton="checkPermission('prepstudent_store')"
		                      :isColumns="true"
                              :isImportButton="checkPermission('prepstudent_import')"
                              :actions="actions"
                              @import-button-click="importData"
		                      @new-button-click="showModal('create')"
		                      @filter-div-status="datatable.filterStatus=$event"
	            >
	                <template v-slot:columns>
	                    <div class="mb-1" v-for="(column,key) in datatable.columns">
	                        <b-form-checkbox
	                            :id="'checkbox-'+key"
	                            :name="'checkbox-'+key"
	                            :value="false"
	                            :unchecked-value="true"
	                            v-model="column.hidden"
	                            v-if="column.field!='buttons'"
	                        >
	                            {{ column.label }}
	                        </b-form-checkbox>
	                    </div>
	                </template>
	            </HeaderMobile>
	        </template>
            <datatable-filter v-show="datatable.filterStatus" @filter="filter" @filterClear="filterClear" :exportExcel="true" @exportExcel="exportExcel">
                <b-row>
                    <b-col sm="6" md="3" lg="2" xl="2">
                        <b-form-group :label="$t('academic_year')">
                            <academic-years-selectbox v-model="datatable.queryParams.filter.academic_year"></academic-years-selectbox>
                        </b-form-group>
                    </b-col>
                    <b-col sm="6" md="3" lg="2" xl="2">
                        <b-form-group :label="$t('module')">
                            <parameter-selectbox code="prep_modules" size="sm" v-model="datatable.queryParams.filter.module"></parameter-selectbox>
                        </b-form-group>
                    </b-col>
                    <b-col sm="6" md="3" lg="2" xl="2">
                         <b-form-group :label="$t('prep_levels')">
                            <parameter-selectbox code="prep_levels" v-model="datatable.queryParams.filter.level"></parameter-selectbox>
                        </b-form-group>
                    </b-col>
                     <b-col sm="6" md="3" lg="2" xl="2">
                         <b-form-group :label="$t('class')">
                            <parameter-selectbox code="prep_class" v-model="datatable.queryParams.filter.class"></parameter-selectbox>
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col sm="6" md="4" lg="2" xl="2">
                        <b-form-group :label="$t('student_number')">
                            <b-form-input type="number" v-model="datatable.queryParams.filter.student_number"></b-form-input>
                        </b-form-group>
                    </b-col>
                    <b-col sm="6" md="3" lg="2" xl="2">
                         <b-form-group :label="$t('name')">
                            <b-form-input type="text" v-model="datatable.queryParams.filter.name">
                            </b-form-input>
                        </b-form-group>
                    </b-col>
                    <b-col sm="6" md="3" lg="2" xl="2">
                        <b-form-group :label="$t('surname')">
                            <b-form-input type="text" v-model="datatable.queryParams.filter.surname">
                            </b-form-input>
                        </b-form-group>
                    </b-col>
                    <b-col sm="6" md="4" lg="4" xl="4">
                        <b-form-group :label="$t('registration_type')">
                            <parameter-selectbox code="registration_types" v-model="datatable.queryParams.filter.registration_type">
                            </parameter-selectbox>
                        </b-form-group>
                    </b-col>
                    <b-col sm="6" md="3" lg="2" xl="2">
                        <b-form-group :label="$t('gender')">
                            <gender-selectbox v-model="datatable.queryParams.filter.gender">
                            </gender-selectbox>
                        </b-form-group>
                    </b-col>
                </b-row>
            </datatable-filter>
            <datatable :isLoading.sync="datatable.isLoading"
                       :columns="datatable.columns"
                       :rows="datatable.rows"
                       :total="datatable.total"
                       :queryParams="datatable.queryParams"
                       @on-page-change="onPageChange"
                       @on-sort-change="onSortChange"
                       @on-per-page-change="onPerPageChange"
                       v-show="datatable.showTable"
            >
            </datatable>
            <CommonModal ref="formModal" size="md" :onHideOnlyX="true" @bHideModalHeaderClose="closeModal" :footer="true" :footer-class="'justify-content-center'">
                <template v-slot:CommonModalTitle>
                    <div>{{ formProcess=='new' ? $t('create').toUpper():''}}</div>
                    <div>{{ formProcess=='new' ? $t('update').toUpper():''}}</div>
                    <div>{{ formProcess=='import' ? $t('import').toUpper():''}}</div>
                </template>
                <template v-slot:CommonModalContent>
                    <div v-if="formProcess === 'create' || formProcess === 'update'">
                        <ValidationObserver ref="form">
                            <div class="row">
                                <div class="col-12 col-sm-6 col-md-4">
                                     <ValidationProvider name="academic_year" rules="required" v-slot="{valid, errors}">
                                        <b-form-group :label="$t('academic_year')">
                                            <academic-years-selectbox v-model="form.academic_year" :validate-error="errors[0]"></academic-years-selectbox>
                                        </b-form-group>
                                    </ValidationProvider>
                                </div>
                                <div class="col-12 col-sm-6 col-md-4">
                                    <ValidationProvider name="module" rules="required" v-slot="{valid, errors}">
                                        <b-form-group :label="$t('module')">
                                            <module-selectbox :academic_year="form.academic_year" v-model="form.module" :validate-error="errors[0]"></module-selectbox>
                                        </b-form-group>
                                    </ValidationProvider>
                                </div>
                                <div class="col-12 col-sm-6 col-md-4">
                                    <ValidationProvider name="class" rules="required" v-slot="{valid, errors}">
                                        <b-form-group :label="$t('level')">
                                            <parameter-selectbox code="prep_levels" v-model="form.level" :validate-error="errors[0]"></parameter-selectbox>
                                        </b-form-group>
                                    </ValidationProvider>
                                </div>
                                <div class="col-12 col-sm-6 col-md-4">
                                    <ValidationProvider name="class" rules="required" v-slot="{valid, errors}">
                                        <b-form-group :label="$t('class')">
                                            <prep-classes-selectbox :academic_year="form.academic_year" :module="form.module" :level="form.level" v-model="form.class_id" :validate-error="errors[0]"></prep-classes-selectbox>
                                        </b-form-group>
                                    </ValidationProvider>
                                </div>
                                <div class="col-12 col-sm-6 col-md-8">
                                    <!-- See this.validateStudent() for validation logic. -->
                                    <ValidationProvider name="student" rules="" v-slot="{valid, errors}">
                                        <b-form-group :label="$t('name_surname')">
                                            <students-auto-complete
                                                :value="`${form.name} ${form.surname}`"
                                                @user-input="form.user=$event"
                                                :validate-error="errors[0]"></students-auto-complete>
                                        </b-form-group>
                                    </ValidationProvider>
                                </div>
                            </div>
                        </ValidationObserver>
                    </div>
                    <import-excel v-if="formProcess === 'import'"
                                  :importMethod="importAsExcel"
                                  :format-excel="$t('prep_students_import_excel_format')"
                                  @successProcess="closeModal"/>
                </template>
                <template v-slot:CommonModalFooter>
                    <b-form-group>
                        <b-button variant="primary" @click="formCreate" v-if="formProcess=='create'">{{ $t('save') }}</b-button>
                        <b-button variant="primary" @click="formUpdate" v-if="formProcess=='update'">{{ $t('update') }}</b-button>
                    </b-form-group>
                </template>
            </CommonModal>
            <CommonModal ref="massAbsenceUpdate" size="md" :onHideOnlyX="true" @bHideModalHeaderClose="closeModal" >
                <template v-slot:CommonModalTitle>
                    {{$t('mass_absence_update')}}
                </template>
                <template v-slot:CommonModalContent>
                   <MaasAbsenceUpdate v-if="formProcess=='massAbsenceUpdate'"/>
                </template>
            </CommonModal>
        </app-layout>
    </div>
</template>
<script>
    import AppLayout from "@/layouts/AppLayout"
    import Header from "@/layouts/AppLayout/Header"
    import HeaderMobile from "@/layouts/AppLayout/HeaderMobile"
    import DatatableFilter from "@/components/datatable/DatatableFilter";
    import Datatable from "@/components/datatable/Datatable";

    import AcademicYearsSelectbox from "@/components/interactive-fields/AcademicYearsSelectbox";
    import ParameterSelectbox from "@/components/interactive-fields/ParameterSelectbox";
    import ModuleSelectbox from "@/components/interactive-fields/ModuleSelectbox";
    import PrepClassesSelectbox from "@/components/interactive-fields/PrepClassesSelectbox";
    import StudentsAutoComplete from "@/components/interactive-fields/StudentsAutoComplete";
    import CommonModal from "@/components/elements/CommonModal";
    import {ValidationProvider, ValidationObserver} from "vee-validate"
    import qs from 'qs'
    import GenderSelectbox from "@/components/interactive-fields/GenderSelectbox";

    import importExcel from "./ImportExcel.vue";

    import PrepStudentService from "@/services/PrepStudentService";
    import MaasAbsenceUpdate from "@/modules/prepStudents/pages/MaasAbsenceUpdate.vue";
    import CourseService from "@/services/CourseService";

    export default {
        components: {
            MaasAbsenceUpdate,
            AppLayout,
            Header,
	        HeaderMobile,
            DatatableFilter,
            Datatable,
            CommonModal,
            ValidationProvider,
            ValidationObserver,
            AcademicYearsSelectbox,
            ParameterSelectbox,
            ModuleSelectbox,
            PrepClassesSelectbox,
            StudentsAutoComplete,
            GenderSelectbox,
            importExcel
        },
        metaInfo() {
            return {
                title: this.$t('prep_students')
            }
        },
        data() {
            return {
                datatable: {
                    isLoading: false,
                    columns: [
                        {
                            label: '',
                            field: 'buttons',
                            html: true,
                            sortable: false,
                            tdClass: 'p-0 text-center w-40 align-middle',
                            buttons:[
                                {
                                    text: this.$t('edit'),
                                    class: 'ri-search-eye-line align-middle top-minus-1 mr-3 text-muted',
                                    permission: "prepstudent_update",
                                    callback:(row)=>{

                                        this.showModal('update', row.id)
                                    }
                                },
                                {
                                    text: this.$t('delete'),
                                    class: 'ri-delete-bin-7-line align-middle top-minus-1 mr-3 text-muted',
                                    permission: "prepstudent_delete",
                                    callback:(row)=>{
                                        this.formDelete(row.id)
                                    }
                                }
                            ]
                        },
                        {
                            label: this.toUpperCase('id'),
                            field: 'id',
                            hidden: true,
                        },
                        {
                            label: this.toUpperCase('academic_year'),
                            field: 'academic_year',
                            hidden: false,
                        },
                        {
                            label: this.toUpperCase('module'),
                            field: 'module',
                            hidden: false,
                        },
                        {
                            label: this.toUpperCase('level'),
                            field: 'level',
                            hidden: false,
                        },
                        {
                            label: this.toUpperCase('class'),
                            field: 'class',
                            hidden: false,
                        },
                        {
                            label: this.toUpperCase('student_number'),
                            field: 'student_number',
                            hidden: false,
                        },
                        {
                            label: this.toUpperCase('name'),
                            field: 'name',
                            hidden: false,
                        },
                        {
                            label: this.toUpperCase('surname'),
                            field: 'surname',
                            hidden: false,
                        },
                    ],
                    rows: [],
                    total: 0,
                    filterStatus: true,
                    showTable: false,
                    queryParams: {
                        filter: {},
                        sort: '-name',
                        page: 1,
                        limit: 20
                    }
                },
                formProcess: null,
                formId: null,
                form: {},
                actions:[
                    {
                        text: this.$t('delete_all_students'),
                        permission:'prepstudent_bulkdelete',
                        callback: ()=>{
                            this.$swal.fire({
                                text: this.$t('are_you_sure_to_delete'),
                                showCancelButton: true,
                                confirmButtonText: this.$t('yes'),
                                cancelButtonText: this.$t('no'),
                            })
                                .then((result) => {
                                    if (result.isConfirmed) {
                                        const config = {
                                            params: {
                                                ...this.datatable.queryParams.filter
                                            },
                                            paramsSerializer: (params) => qs.stringify(params, {encode: false})
                                        };
                                        PrepStudentService.bulkDelete(config)
                                            .then(response => {
                                                this.$toast.success(this.$t('api.' + response.data.message));
                                                this.getRows();
                                                this.formClear();
                                            })
                                            .catch(error => {
                                                this.showErrors(error)
                                            });
                                    }
                                })
                        }
                    }
                ]
            }
        },
        created() {
            this.filterSet();
            this.formClear();
        },
        methods: {
            filterSet(){
                this.datatable.queryParams.filter = {
                    academic_year: null,
                    module: null,
                    level: null,
                    class: null,
                    name: null,
                    surname: null
                };
            },
            filterClear() {
                this.filterSet()
                this.getRows();
            },
            filter() {
                this.datatable.queryParams.page = 1;
                this.getRows();
            },
            onPageChange(params) {
                this.datatable.queryParams.page = params.currentPage;
                this.getRows();
            },
            onPerPageChange(params) {
                this.datatable.queryParams.limit = params.currentPerPage;
                this.datatable.queryParams.page = 1;
                this.getRows();
            },
            changeColumn(key){
                this.datatable.columns[key].hidden = this.datatable.columns[key].hidden==true ? false:true;
            },
            onSortChange(params) {
                const sortType = params[0].type == 'desc' ? '-' : '';
                this.datatable.queryParams.sort = sortType + params[0].field
                this.getRows();
            },
            getRows() {
                this.datatable.showTable = true;
                this.datatable.isLoading = true;

                const config = {
                    params: {
                        ...this.datatable.queryParams
                    },
                    paramsSerializer: (params) => qs.stringify(params, {encode: false})
                };

                return PrepStudentService.getAll(config)
                        .then((response) => {
                            this.datatable.rows = response.data.data
                            this.datatable.total = response.data.pagination.total
                        })
                        .finally(() => {
                            this.datatable.isLoading = false;
                        });
            },

            showModal(process, id=null){
                this.formProcess = process
                this.formId = null;

                this.formClear();

                if(this.formProcess=='update' && id){
                    PrepStudentService.get(id)
                    .then(response => {
                        this.form  = response.data.data;
                        this.formId = id
                    })
                    .catch(e => {
                        this.showErrors(e)
                    })
                }
                this.$refs.formModal.$refs.commonModal.show();
            },
            formClear(){
                this.form ={
                    academic_year: null,
                    module: null,
                    class_id: null,
                    user: null
                }
            },
            validateStudent(){
                if(!this.form.user){
                    this.$refs.form.errors['student'].push(this.$t('invalid_student'))
                    return false
                }
                return true
            },
            async formCreate(){
                let isValid = await this.$refs.form.validate();
                isValid &= this.validateStudent();

                if (isValid) {
                    let formData = {
                        class_id: this.form.class_id,
                        student_program_id: this.form.user.student_program_id
                    }
                    PrepStudentService.store(formData)
                    .then(response => {
                        this.$toast.success(this.$t('api.' + response.data.message));
                        this.getRows();
                        this.formClear();
                        this.$refs.formModal.$refs.commonModal.hide()
                    })
                    .catch(e => {
                        this.showErrors(e)
                    })
                }
            },
            async formUpdate(){
                let isValid = await this.$refs.form.validate();
                isValid &= this.validateStudent();

                if (isValid && this.formId) {
                    let formData = {
                        ...this.form
                    }

                    PrepStudentService.update(this.formId, formData)
                    .then(response => {
                        this.$toast.success(this.$t('api.' + response.data.message));
                        this.getRows();
                        this.formClear();
                        this.$refs.formModal.$refs.commonModal.hide()
                    })
                    .catch(e => {
                        this.showErrors(e)
                    })
                }
            },
            formDelete(id){
                this.$swal.fire({
                    text: this.$t('are_you_sure_to_delete'),
                    showCancelButton: true,
                    confirmButtonText: this.$t('yes'),
                    cancelButtonText: this.$t('no'),
                })
                .then((result) => {
                    if (result.isConfirmed) {
                        PrepStudentService.del(id)
                        .then(response => {
                            this.$toast.success(this.$t('api.' + response.data.message));
                            this.getRows();
                            this.formClear();
                        })
                        .catch(error => {
                            this.$toast.error(this.$t('api.' + error.data.message));
                        });
                    }
                })
            },
            importData(){
                this.formProcess = 'import'
                this.$refs.formModal.$refs.commonModal.show()
            },
            closeModal() {
                this.formProcess = null
                this.$refs.formModal.$refs.commonModal.hide()
                this.getRows()
            },
            importAsExcel(isValid,defineForm,form){
                if (isValid) {
                    let formData = new FormData();
                    formData.append('file',defineForm.file[0])
                    formData.append('academic_year',defineForm.academic_year)

                    PrepStudentService.importExcel(defineForm.module, formData).then(response => {
                        this.$toast.success(this.$t('api.' + response.data.message));
                        this.$router.push('/job/logs/detail/' + response.data.data.log_id)
                        this.$emit('successProcess')
                    }).catch(e => {this.showErrors(e,form)})
                }
            },
            showMaasAbsenceUpdate(){
                this.formProcess='massAbsenceUpdate'
                this.$refs.massAbsenceUpdate.$refs.commonModal.show();
            },
            exportExcel(){
                const config = {
                    params: {
                        ...this.datatable.queryParams
                    },
                    paramsSerializer: (params) => qs.stringify(params, { encode: false })
                };

                return PrepStudentService.prepAttendances(config)
                    .then((response) => {
                        this.$router.push('/job/logs/detail/' + response.data.data.log_id)
                    })
                    .catch(e => {
                        this.showErrors(e)
                    });
            }

        }
    }
</script>

